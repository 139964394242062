import { Environment } from './types';

export const environment: Environment = {
  production: true,
  featureSet: 'hoist_finance',
  showErrorTraces: false,
  oauthBaseUrl: 'https://hoist.auth.eu-west-1.amazoncognito.com',
  oauthClientId: '79ee18lc5su210vqcd6fiu9bdu',
  oauthScopes: 'openid+email',
  apiBaseUrl: 'https://r4bhqk29cb.execute-api.eu-north-1.amazonaws.com/Prod',
  documentationApiUrl:
    'https://atlk65auk3.execute-api.eu-north-1.amazonaws.com/documentation/oasys', //Docsie not working for hoist since not access to main account yet
  notificationApiBaseUrl:
    'https://mlay0ivam7.execute-api.eu-north-1.amazonaws.com',
};
